import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import HeroMain from '../components/hero-main'
import Container from '../components/container'

import ArticlePreview from '../components/article-preview'
import BookRecommendation from '../components/book-recommendation'
import Categories from '../components/categories'
import BookRecommendation2 from '../components/book-recommendation-2'

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    const [author] = get(this, 'props.data.allContentfulPerson.nodes')
    const categories = get(this, 'props.data.allContentfulCategory.nodes')

    return (
      <Layout location={this.props.location} className="bg-blue-500">
        <Hero
          image={author.heroImage.gatsbyImage}
          title="Szakmai szemmel"
          content={author.shortBio}
        />

        <Categories categories={categories} />

        <ArticlePreview posts={posts} title="Legfrisebb bejegyzések" />
        <div id="books">
          <BookRecommendation />
          <BookRecommendation2 />
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "hu-HU" } }
      limit: 3
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY. MM. DD.")
        category {
          name
        }
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
    allContentfulCategory(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "hu-HU" } }
    ) {
      nodes {
        id
        name
        slug
        thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "4iQWi7ZnCzsCri0BIyaDVG" } }
    ) {
      nodes {
        name
        shortBio {
          raw
        }
        title
        heroImage: image {
          gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1180)
        }
      }
    }
  }
`
