import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from './container'

const BookRecommendation = () => {
  return (
    <Container>
      {/* <div className="relative py-24 px-8 bg-[#79C6FD] rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
        <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
          <StaticImage
            className="w-full h-full object-cover"
            src="../images/IMG_5584.jpg"
            alt="Szakmai szemmel könyv"
          />
        </div>
        <div className="relative lg:col-span-2">
          <span className="block text-2xl font-medium  text-white">
            Megjelent Szakmai szemmel című szakkönyvem
          </span>
          <blockquote className="mt-6 text-white">
            <p className="font-medium">
              A Szakmai szemmel névre hallgató oldalamon megjelent
              bejegyzéseimben az oktató-nevelő munka több elemében kívánok
              rávilágítani arra, hogy véleményem szerint a kialakult szemlélet
              és gondolkodás okoz némi zavart az utánpótlás-nevelésben,
              írásaimmal pedig ezen két szegmensben szeretnék változásokat
              előidézni. A könyvet, amely ezen írásokon alapszik, nemcsak a
              kézilabda sportágban tevékenykedőknek, vagy tanároknak és edzőknek
              ajánlom, hanem a szülőknek is.
            </p>
            <footer className="mt-6">
              <p className="flex flex-col font-medium">
                <span>Ára: 4000 Ft</span>
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSec8KkR9DzSF_g6qJUinQrXS8QtfSRBr9qTmdWqXi5TbtiMEA/viewform"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-sky-600 hover:bg-sky-50"
              >
                Megrendelés
              </a>
            </footer>
          </blockquote>
        </div>
      </div> */}
      <div className="bg-white">
        <div className="bg-[#79C6FD] rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h3 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Megjelent</span>
                <span className="block">Szakmai szemmel című szakkönyvem</span>
              </h3>
              <p className="mt-4 text-lg leading-6 text-white">
                A Szakmai szemmel névre hallgató oldalamon megjelent
                bejegyzéseimben az oktató-nevelő munka több elemében kívánok
                rávilágítani arra, hogy véleményem szerint a kialakult szemlélet
                és gondolkodás okoz némi zavart az utánpótlás-nevelésben,
                írásaimmal pedig ezen két szegmensben szeretnék változásokat
                előidézni. A könyvet, amely ezen írásokon alapszik, nemcsak a
                kézilabda sportágban tevékenykedőknek, vagy tanároknak és
                edzőknek ajánlom, hanem a szülőknek is.
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSec8KkR9DzSF_g6qJUinQrXS8QtfSRBr9qTmdWqXi5TbtiMEA/viewform"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-sky-600 hover:bg-sky-50"
              >
                Megrendelés (4000 Ft)
              </a>
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <StaticImage
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src="../images/image002.jpg"
              alt="Szakmai szemmel könyv"
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default BookRecommendation
