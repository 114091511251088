import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from './container'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import BlogPostTemplate from '../templates/blog-post'
import { Link } from 'gatsby'

const Categories = ({ categories }) => {
  return (
    <Container>
      <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"></h2>

      <div className="space-y-12">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            <mark className="inline-block pb-[0.5em] leading-[0em] bg-[#79C6FD]">
              Kategóriák
            </mark>
          </h2>
        </div>
        <ul
          role="list"
          className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8"
        >
          {categories.map((category) => (
            <li key={category.name}>
                          <Link to={`/category/${category.slug}`}>

              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  {/* <StaticImage
                      className="object-cover shadow-lg rounded-lg"
                      src={category.thumbnail}
                      alt=""
                    /> */}
                  <div className="relative cursor-pointer rounded">
                    <GatsbyImage
                      image={getImage(category.thumbnail)}
                      alt={category.name}
                      className="rounded-xl h-40"
                    />
                    <div className="absolute bottom-0 rounded-xl bg-gradient-to-t from-[#0061a0] w-full p-5 pt-16 text-white text-lg leading-6 font-medium space-y-1">
                      <h3>{category.name}</h3>
                      {/* <p className="text-indigo-600">{person.role}</p> */}
                    </div>
                    <div className="absolute top-0  right-0 bottom-0 left-0 bg-gray-50 bg-opacity-20 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"></div>
                  </div>
                </div>
              </div>
              </Link>

            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}

export default Categories
