import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from './container'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BookRecommendation2 = ({ title, content, imgSrc, color }) => {
  return (
    <Container>
      <div className="bg-white">
        <div className="bg-[#A1D345] rounded-lg shadow-xl overflow-hidden lg:flex lg:gap-4">
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <StaticImage
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20 "
              src="../images/image001.png"
              alt="Szakmai szemmel könyv"
            />
          </div>
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20 lg:flex-1">
            <div className="lg:self-center">
              <h3 className="text-3xl font-extrabold  text-grey-900 sm:text-4xl">
                <span className="block">
                  A kézilabdázás oktatása kisiskoláskorban
                </span>
              </h3>
              <p className="mt-4 text-lg leading-6  text-grey-800">
                Képzeljük csak el hogy egyszerűen azért járnak edzésre a
                gyerekek, mert élvezik, amit csinálnak, mert ez jó érzéssel
                tölti el őket. Ha viszont a gyerekben az tudatosul hogy valamit
                kötelezően végre kell hajtania, az első adandó alkalommal amikor
                lehetőséget lát valami érdekesebb, játékosabb elfoglaltságra
                szert tenni, eltávolodik. Nos, én is nagyon jól tudom, hogy itt
                egy paradoxont kell áthidalni, ami nagyon megnehezíti az általam
                sugallt szemléletváltást. A játékon belül mindig van győztes és
                vesztes. Hogyan lehet mindkét elvárásnak eleget tenni? Egyszerre
                legyek jó pedagógus és jó szakember is? Egy időben legyek képes
                nevelőmunkát ellátni, ugyanakkor terelgessem a kiugró
                tehetségeket a versenysport irányába? Hiszen kézilabdázni csak
                csapatban lehet, ráadásul egy másik csapattal szemben.
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe59noo6CkIQhTta9dnq9_GaHkL8nNQ2GbIV2HK5yhMw-wPIA/viewform?fbclid=IwAR00VZpYcscBhizC5kyxezrzdJd1rRdlXUYdxIouW9oN82_goWCY_LmuOOY"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-green-600 hover:bg-green-50"
              >
                Megrendelés (4000 Ft)
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="relative py-24 px-8 bg-[#A1D345] rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
        <div className="absolute inset-0 opacity-30 filter saturate-0 mix-blend-multiply">
          <StaticImage
            className="w-full h-full object-cover"
            src="../images/IMG_5572.jpg"
            alt="Szakmai szemmel könyv"
          />
        </div>
        <div className="relative lg:col-span-2">
          <span className="block text-2xl font-medium  text-grey-900">
            A kézilabdázás oktatása kisiskoláskorban
          </span>
          <blockquote className="mt-6 text-grey-800">
            <p className="font-medium">
              Képzeljük csak el hogy egyszerűen azért járnak edzésre a gyerekek,
              mert élvezik, amit csinálnak, mert ez jó érzéssel tölti el őket.
              Ha viszont a gyerekben az tudatosul hogy valamit kötelezően végre
              kell hajtania, az első adandó alkalommal amikor lehetőséget lát
              valami érdekesebb, játékosabb elfoglaltságra szert tenni,
              eltávolodik. Nos, én is nagyon jól tudom, hogy itt egy paradoxont
              kell áthidalni, ami nagyon megnehezíti az általam sugallt
              szemléletváltást. A játékon belül mindig van győztes és vesztes.
              Hogyan lehet mindkét elvárásnak eleget tenni? Egyszerre legyek jó
              pedagógus és jó szakember is? Egy időben legyek képes nevelőmunkát
              ellátni, ugyanakkor terelgessem a kiugró tehetségeket a
              versenysport irányába? Hiszen kézilabdázni csak csapatban lehet,
              ráadásul egy másik csapattal szemben.
            </p>
            <footer className="mt-6">
              <p className="flex flex-col font-medium">
                <span>Ára: 4000 Ft</span>
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSec8KkR9DzSF_g6qJUinQrXS8QtfSRBr9qTmdWqXi5TbtiMEA/viewform"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-sky-50"
              >
                Megrendelés
              </a>
            </footer>
          </blockquote>
        </div>
      </div> */}
    </Container>
  )
}

export default BookRecommendation2
