import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Container from './container'
import { v4 as uuidv4 } from 'uuid';

const ArticlePreview = ({ posts, title }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <Container className="space-y-12">
      <div className="space-y-12">
        {title && (
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {title}
            </h2>
          </div>
        )}
        <div key={title} className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div key={uuidv4()} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <Link className="flex-shrink-0" to={`/blog/${post.slug}`}>
                <GatsbyImage alt="h-48 w-full object-cover" image={post.heroImage?.gatsbyImage} />

              </Link>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <Link to={`/blog/${post.slug}`}>
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <div className="mt-3 text-base text-gray-500">
                      {post.description?.raw && renderRichText(post.description)}
                    </div>
                  </Link>

                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    {/* <a href={post.author.href}>
                   <span className="sr-only">{post.author.name}</span>
                   {/* <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" /> 
                 </a> */}
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {/* <a href={post.author.href} className="hover:underline">
                     {post.author.name}
                   </a> */}
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.publishDate}>{post.publishDate}</time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default ArticlePreview